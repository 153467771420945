import "@hotwired/turbo-rails";
import "bootstrap";
import 'flickity';
import "controllers"

import '../../assets/stylesheets/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';

import 'flickity/dist/flickity.css';

import initAOS from 'components/aos';
import initFlickity from 'components/flickity'

const initApp = () => {
  initAOS()
  initFlickity()
};

// list of all turbo events here: https://turbo.hotwired.dev/reference/events
// runs after initial load
window.addEventListener('load', initApp);
// runs after every page render
document.addEventListener('turbo:render', initApp);
